import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatPrice } from '../../../utils/currency'
import {
    PayPalScriptProvider,
    usePayPalScriptReducer,
    PayPalButtons,
} from '@paypal/react-paypal-js'
import { navigate } from 'gatsby'

// TODO: CartData initialize
const currency = 'EUR'
const style = { layout: 'vertical' }

const SCRIPT_PROVIDER_OPTIONS = {
    'client-id': 'AfRvvgpmRjIe2LrFjIDiS2r2jzP-jiY92WR0mG5A-sAe4NnjRw10cXblF8dPzf2Z_QPMIPf6i-35-P-V',
    currency: currency,
    components: 'buttons',
}

interface IProps {
    readonly totalQuantity: number
    readonly total: number
}

interface IPropsPaypal {
    readonly total: string
    readonly currency: string
    readonly showSpinner: boolean
}

const StyledMobileTotalGrid = styled(Grid)`
    display: none;
    border-top: 1px solid var(--text-disabled-color);
    position: sticky;
    bottom: 0;
    background: white;
    padding: 12px var(--center-content-padding);
    margin: 0 calc(-1 * var(--center-content-padding));
    width: 100vw;

    ${({ theme }) => theme.breakpoints.down('md')} {
        display: flex;
    }
`

const StyledCountsGrid = styled(Grid)`
    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledTotalQuantityRow = styled(StyledRow)`
    margin-bottom: 7px;
`

const StyledQuantityTypography = styled(Typography)`
    font-size: 20px;
`

const StyledTotalTypography = styled(Typography)`
    font-size: 24px;
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-right: 32px;
    }
`

const StyledButton = styled(Button)`
    margin-bottom: 16px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
    margin: 0;
`
const ButtonWrapper: FC<IPropsPaypal> = ({ total, currency, showSpinner }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer()

    useEffect(() => {
        dispatch({
            type: 'resetOptions',
            value: {
                ...options,
                currency: currency,
            },
        })
    }, [currency, showSpinner])

    return (
        <>
            {showSpinner && isPending && <div className='spinner' />}
            <PayPalButtons
                disabled={false}
                forceReRender={[total, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: total,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            return orderId
                        })
                }}
                onCancel={function () {
                    navigate('/cart/')
                }}
                onError={function () {
                    navigate('/order-error/')
                }}
                onApprove={async function (data, actions) {
                    if (!actions || !actions.order) {
                        return
                    }
                    const payer = await actions.order.capture()
                    await navigate('/order/', {
                        state: { payer },
                    })
                }}
            ></PayPalButtons>
        </>
    )
}

const StickyTotal: FC<IProps> = ({ totalQuantity, total }) => {
    const { t } = useTranslation()

    return (
        <StyledMobileTotalGrid container justifyContent='space-between'>
            <StyledCountsGrid item sm={3}>
                <StyledSection>
                    <StyledTotalQuantityRow>
                        <StyledQuantityTypography>
                            {totalQuantity} {t('cart:items')}
                        </StyledQuantityTypography>
                    </StyledTotalQuantityRow>
                    <StyledRow>
                        <StyledTotalTypography>{t('cart:total')}</StyledTotalTypography>
                        <StyledTotalTypography>{formatPrice(total)}</StyledTotalTypography>
                    </StyledRow>
                </StyledSection>
            </StyledCountsGrid>
            <Grid item sm={6} xs={12}>
                <StyledSection>
                    <StyledButton variant='contained' color='primary'>
                        {t('cart:place-order')}
                    </StyledButton>
                    <StyledFormControlLabel
                        control={<Checkbox name='one-delivery' color='primary' />}
                        label={t('cart:one-delivery')}
                    />
                </StyledSection>
                Process order with
                <div style={{ maxWidth: '750px', minHeight: '200px' }}>
                    <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>
                        <ButtonWrapper
                            currency={currency}
                            total={total.toFixed(2)}
                            showSpinner={false}
                        />
                    </PayPalScriptProvider>
                </div>
            </Grid>
        </StyledMobileTotalGrid>
    )
}

export default StickyTotal
