import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatPrice } from '../../../utils/currency'
import {
    PayPalScriptProvider,
    usePayPalScriptReducer,
    PayPalButtons,
} from '@paypal/react-paypal-js'
import { navigate } from 'gatsby'

// TODO: CartData initialize
const currency = 'EUR'
const style = { layout: 'vertical' }

const SCRIPT_PROVIDER_OPTIONS = {
    'client-id': 'AfRvvgpmRjIe2LrFjIDiS2r2jzP-jiY92WR0mG5A-sAe4NnjRw10cXblF8dPzf2Z_QPMIPf6i-35-P-V',
    currency: currency,
    components: 'buttons',
}

interface IProps {
    readonly totalQuantity: number
    readonly total: number
}
interface IPropsPaypal {
    readonly total: string
    readonly currency: string
    readonly showSpinner: boolean
}

const StyledBox = styled.div`
    border: 1px solid var(--text-disabled-color);
    border-radius: 4px;
    width: 100%;
    min-height: 251px;
    padding: 16px 16px 7px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledTotalQuantityRow = styled(StyledRow)`
    margin-bottom: 8px;
`

const StyledQuantityTypography = styled(Typography)`
    font-size: 20px;
`

const StyledQuantityCountTypography = styled(Typography)`
    font-size: 20px;
`

const StyledTotalTypography = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`

const StyledButton = styled(Button)`
    margin-bottom: 12px;
`

const StyledDeliveryTypography = styled(Typography)`
    color: var(--text-disabled-color);
    margin-bottom: 7px;
`

const ButtonWrapper: FC<IPropsPaypal> = ({ total, currency, showSpinner }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer()

    useEffect(() => {
        dispatch({
            type: 'resetOptions',
            value: {
                ...options,
                currency: currency,
            },
        })
    }, [currency, showSpinner])

    return (
        <>
            {showSpinner && isPending && <div className='spinner' />}
            <PayPalButtons
                disabled={false}
                forceReRender={[total, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: total,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            return orderId
                        })
                }}
                onCancel={function () {
                    navigate('/cart/')
                }}
                onError={function () {
                    navigate('/order-error/')
                }}
                onApprove={async function (data, actions) {
                    if (!actions || !actions.order) {
                        return
                    }
                    const payer = await actions.order.capture()
                    await navigate('/order/', {
                        state: { payer },
                    })
                }}
            ></PayPalButtons>
        </>
    )
}

const DesktopTotal: FC<IProps> = ({ totalQuantity, total }) => {
    const { t } = useTranslation()
    return (
        <StyledBox>
            <StyledSection>
                <StyledTotalQuantityRow>
                    <StyledQuantityTypography>{t('cart:total-quantity')}</StyledQuantityTypography>
                    <StyledQuantityCountTypography>{totalQuantity}</StyledQuantityCountTypography>
                </StyledTotalQuantityRow>
                <StyledRow>
                    <StyledTotalTypography>{t('cart:total')}</StyledTotalTypography>
                    <StyledTotalTypography>{formatPrice(total)}</StyledTotalTypography>
                </StyledRow>
            </StyledSection>
            <StyledSection>
                <StyledButton variant='contained' color='primary'>
                    {t('cart:place-order')}
                </StyledButton>
                <StyledDeliveryTypography>
                    {t('cart:delivery-date-determined-later')}
                </StyledDeliveryTypography>
                <FormControlLabel
                    control={<Checkbox name='one-delivery' color='primary' />}
                    label={t('cart:one-delivery')}
                />
            </StyledSection>
            Process order with
            <div style={{ maxWidth: '750px', minHeight: '200px' }}>
                <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>
                    <ButtonWrapper
                        currency={currency}
                        total={total.toFixed(2)}
                        showSpinner={false}
                    />
                </PayPalScriptProvider>
            </div>
        </StyledBox>
    )
}

export default DesktopTotal
