// import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { StoreToolType } from '../../interfaces/store-tool.interface'
import { setCartTools } from '../../state/actions/tools/set-cart-tools'
import { AppDispatchType } from '../../state/interfaces/app-dispatch.type'
import { IReduxState } from '../../state/interfaces/state.interface'
import { replaceCartToolsInLS } from '../../utils/local-storage'
import CartItem from './CartItem'
import DesktopTotal from './DesktopTotal'
import StickyTotal from './StickyTotal'

interface IProps {
    readonly storeTools: StoreToolType[]
    readonly changeCartToolQuantity: (id: number, quantity: number) => void
}

const StyledContainer = styled.div`
    padding-top: 32px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding-top: 20px;
    }
`

const StyledDivider = styled.div`
    width: 100vw;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.24);
    margin-top: 20px;
    margin-left: calc(-1 * var(--center-content-padding));
`

const StyledTitleTypography = styled(Typography)`
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 20px;
    }
`

const StyledMobileTotalItems = styled.div`
    display: none;
    margin-top: 16px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
    }
`

const StyledMobileTotalTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`

const StyledContentGrid = styled(Grid)`
    margin-top: 40px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-top: 20px;
    }
`

const StyledCartList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 40px 0;
`

const StyledCartItemContainer = styled.li`
    &:not(:last-child) {
        padding-bottom: 52px;
        margin-bottom: 52px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        &:not(:last-child) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
`

const StyledDesktopTotalGrid = styled(Grid)`
    ${({ theme }) => theme.breakpoints.down('md')} {
        display: none;
    }
`

const Cart: FC<IProps> = ({ storeTools, changeCartToolQuantity }) => {
    const { t } = useTranslation()
    const reduxDispatch: AppDispatchType = useDispatch()
    // const { isAuthenticated } = useAuth0()

    const cartTools = useSelector((state: IReduxState) => state.tools.cartTools)
    const totalQuantity = cartTools.reduce((acc, { quantity }) => acc + quantity, 0)
    const total = cartTools.reduce((acc, { id, quantity }) => {
        const price = storeTools.find((storeTool) => storeTool.id === id)?.price || 0
        return acc + price * quantity
    }, 0)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    // if (!isAuthenticated) {
    //     return null
    // }

    return (
        <StyledContainer>
            <Grid container direction='column'>
                <Grid container item justifyContent='space-between' sm={8} xs={12}>
                    <StyledTitleTypography variant='h4'>
                        {t('cart:send-request')}
                    </StyledTitleTypography>
                    <Button
                        variant='outlined'
                        size='small'
                        onClick={() => {
                            replaceCartToolsInLS([])
                            reduxDispatch(setCartTools([]))
                        }}
                    >
                        {t('clear-all')}
                    </Button>
                </Grid>
                <StyledMobileTotalItems>
                    <StyledMobileTotalTypography>{`${totalQuantity} ${t(
                        'cart:items'
                    )}`}</StyledMobileTotalTypography>
                </StyledMobileTotalItems>
            </Grid>
            {isMobile && <StyledDivider />}
            <StyledContentGrid container justifyContent='space-between'>
                <Grid item md={8} xs={12}>
                    <StyledCartList>
                        {storeTools.map((storeTool) => {
                            return (
                                <StyledCartItemContainer key={storeTool.id}>
                                    <CartItem
                                        storeTool={storeTool}
                                        changeCartToolQuantity={changeCartToolQuantity}
                                    />
                                </StyledCartItemContainer>
                            )
                        })}
                    </StyledCartList>
                </Grid>
                {storeTools.length > 0 && (
                    <StyledDesktopTotalGrid item xs={3}>
                        <DesktopTotal totalQuantity={totalQuantity} total={total} />
                    </StyledDesktopTotalGrid>
                )}
            </StyledContentGrid>

            <StickyTotal totalQuantity={totalQuantity} total={total} />
        </StyledContainer>
    )
}

export default Cart
